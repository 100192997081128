import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useLocation} from "react-router-dom";
import axios from "axios";

import Container from "../../components/Container";
import Card from "../../components/Сard";
import Table from "../../components/table/Table";
import TableParams from "./PostbackSetupCustomListTableParams";
import ajax from "../../../data/ajax";
import {SelectDataFormatter, UrlCreator} from "../../../components/select_data";
import {consoleLogResponse} from "../../../components/development";
import LoadingWrapper from "../../components/LoadingWrapper";
import ButtonFilter from "../../components/ButtonFilter";
import SelectMulti from "../../components/select/SelectMulti";
import {useSelect} from "../../../components/input-hook";
import Filter from "../../../components/filter";
import TableColumns from "../../components/table/TableColumns";
import {ObjectRemoveEmptyForUrl} from "../../../components/helpers";
import BackendRequest from "../../../data/BackendRequest";

const PostbackSetupCustomList = ({user}) => {
    const location = useLocation();
    const locationPathname = location.pathname;

    const navigate = useNavigate();

    const [isLoadedFilterData, setIsLoadedFilterData] = useState(false);

    const localStorageTableKey = 'table_postback_setup_custom_list';
    const [tableParams] = TableParams();

    const [tableGroupHiddenColumns] = useState([]);
    const [tableUserHiddenColumns, setUserTableHiddenColumns] = useState();
    const [tableHiddenColumns, setTableHiddenColumns] = useState([]);
    useEffect(() => {
        setTableHiddenColumns(tableGroupHiddenColumns.concat(tableUserHiddenColumns))
    }, [tableGroupHiddenColumns, tableUserHiddenColumns]); // eslint-disable-line react-hooks/exhaustive-deps

    const [filterButton, setFilterButton] = useState(0);

    const inputFilterAccount = useSelect([]);

    const [dataRequestParams, setDataRequestParams] = useState({});
    const [{data, isLoading}, setUrlParams] = BackendRequest('ex-stat/zone-setup-conversions');

    useEffect(() => {
        const fetchData = async () => {
            const requestConfig = ajax.getBaseConfig();
            const promises = [
                axios.get(UrlCreator.accounts({'type': 'advertiser'}), requestConfig),
                axios.get(UrlCreator.accounts({'type': 'publisher'}), requestConfig),
            ];
            Promise.all(promises).then(function (results) {
                consoleLogResponse(results);

                Filter.applyUrlToFilter(setDataRequestParams, location, [
                    {
                        key: 'accounts',
                        data: SelectDataFormatter.accounts(results[0].data),
                        input: inputFilterAccount,
                    },
                ])

                setIsLoadedFilterData(true);
            });
        };

        setIsLoadedFilterData(false);
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (isLoadedFilterData) {
            setDataRequestParams({
                accounts: inputFilterAccount.value,
            })
        }
    }, [inputFilterAccount.value, filterButton]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        navigate(locationPathname + '?' + (new URLSearchParams(ObjectRemoveEmptyForUrl(dataRequestParams))).toString());

        const params = dataRequestParams;
        params.type = 2;
        setUrlParams(params);
    }, [dataRequestParams]);

    return (
        <Container title={"Postbacks"}>
            <Card>
                <div className="row">
                    <Link to={"/postback-setup/custom/create"}><button className="btn btn-primary mr-1">Setup postback</button></Link>
                    <Link to={"/postback-setup/demo"}><button className="btn btn-primary">Postback setup demo</button></Link>
                </div>
            </Card>

            <LoadingWrapper isLoaded={isLoadedFilterData}>
                <Card>
                    <div className="row">
                        <div className="col-md-3">
                            <b>Select account</b>
                            <SelectMulti {...inputFilterAccount.bind}/>
                        </div>
                        <div className="col-md-3">&nbsp;</div>
                        <div className="col-md-3">&nbsp;</div>
                        <div className="col-md-3">&nbsp;</div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <ButtonFilter setFilterButton={v => setFilterButton(v)} />
                        </div>
                        <div className="col-md-6">&nbsp;</div>
                        <div className="col-md-3 text-right">
                            <TableColumns
                                storageKey={localStorageTableKey}
                                columns={tableParams.columns}
                                setUserTableHiddenColumns={v => setUserTableHiddenColumns(v)}
                            />
                        </div>
                    </div>
                </Card>
            </LoadingWrapper>

            <LoadingWrapper isLoaded={!isLoading}>
                <Card>
                    <Table
                        data={data}
                        {...tableParams}
                        hiddenColumns={tableHiddenColumns}
                    />
                </Card>
            </LoadingWrapper>
        </Container>
    );
}

export default PostbackSetupCustomList;
