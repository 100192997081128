import React from "react";
import {Link} from "react-router-dom";
import * as Icon from "react-feather";

import ZoneData from "../../../components/ZoneData";
import ExternalLink from "../../components/ExternalLink";

export default function ZoneZeydooListTableParams() {
    const keyZoneId = 'zone_id';
    const keyOriginZoneId = 'origin_zone_id';

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: keyOriginZoneId,
            },
            {
                Header: 'UID',
                accessor: 'uid',
            },
            {
                Header: 'Offer Id',
                accessor: 'offer_id',
            },
            {
                Header: 'Account',
                accessor: 'pub_account_name',
            },
            // {
            //     Header: 'Site Id',
            //     accessor: 'site_id',
            // },
            // {
            //     Header: 'Site Url',
            //     accessor: 'site_url',
            // },
            // {
            //     Header: 'Direction',
            //     Cell: ({row}) => ZoneData.directionName(row.original['direction_id']),
            //     accessor: 'direction_id',
            // },
            // {
            //     Header: 'Revenue Model',
            //     Cell: ({row}) => ZoneData.revenueModelName(row.original['revenue_model']),
            //     accessor: 'revenue_model',
            // },
            {
                Header: 'Source Acc',
                accessor: 'c_account_name',
            },
            {
                Header: 'Manager',
                accessor: 'c_manager_name',
            },
            {
                Header: 'Title',
                accessor: 'title',
            },
            {
                Header: 'Postback Url',
                accessor: 'postback_url',
            },
            {
                Header: 'Link Url',
                accessor: 'link_url',
            },
            {
                accessor: 'available_link',
                className: 'min',
                Cell: ({row}) => {
                    if (Number(row.original['is_available']) === 1) {
                        return <Link to={"/ex-stat/zone/assign/" + row.original[keyZoneId]} target="_blank" rel="noopener noreferrer"><Icon.UserCheck/></Link>
                    }
                    return "";
                },
            },
            // {
            //     accessor: 'propushme_link',
            //     className: 'min',
            //     Cell: ({row}) => <ExternalLink url={"https://app.propush.me/#/site/" + row.original['origin_site_id'] + "/zone/" + row.original['origin_zone_id']} title={<Icon.ExternalLink/>}/>,
            // },
            // {
            //     accessor: 'edit_2',
            //     className: 'min',
            //     Cell: ({row}) => <Link to={"/ex-stat/zone/edit-propush/" + row.original[keyZoneId]}><Icon.Edit2/></Link>,
            // },
            {
                accessor: 'edit_3',
                className: 'min',
                Cell: ({row}) => <Link to={"/ex-stat/zone/edit/" + row.original[keyZoneId]}><Icon.Edit3/></Link>,
            },
            {
                accessor: 'setup',
                className: 'min',
                Cell: ({row}) => row.original['postback_setup_id'] !== null
                    ? <Link to={"/postback-setup/zones/edit/" + row.original['postback_setup_id']}><Icon.Settings/></Link>
                    : <Link to={"/postback-setup/zones/create/" + row.original[keyZoneId]}><Icon.PlusCircle/></Link>
            },
        ],
        []
    );

    const initialState = {
        sortBy: [
            {
                id: keyOriginZoneId,
                desc: true
            }
        ]
    }

    return [{
        columns,
        initialState
    }];
}
