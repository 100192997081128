const USER_ANDREY = 1;
const USER_DMITRIY = 2;
const USER_189 = 189;
const USER_AS = 77;

export const userListAdmins = () => {
    return [USER_ANDREY, USER_DMITRIY];
}

export const userListAllowClickaduDashboard = () => {
    return [
        USER_ANDREY,
        USER_DMITRIY,
        USER_189,
        USER_AS,
    ];
}

export const allowAdminOptions = (userId) => {
    return userListAdmins().includes(userId);
}

export const allowAdminNftOptions = (userId) => {
    return userListAdmins().includes(userId);
}

export const allowClickaduDashboard = (userId) => {
    return userListAllowClickaduDashboard().includes(userId);
}
