import React, {useState} from 'react';
import {useParams} from "react-router-dom";

import Container from "../../../components/Container";
import TabCustomSettings from "./EditFormTabCustomSettings";
import ZoneInfo from "../../../components/ZoneInfo";

export default function ExStatZoneEditForm() {
    let {zone_id} = useParams();
    const [activeTabID, setActiveTabID] = useState('custom_settings');

    const menuItems = [
        {key: 'custom_settings', title: 'Cabinet data'},
        // {key: 'info', title: 'Info'},
    ];

    return (
        <Container title={"Zone Settings " + zone_id}>
            <div className="row">
                <div className="col-md-3 col-xl-2">
                    <div className="card">
                        <div className="list-group list-group-flush" role="tablist">
                            {menuItems.map((item) => {
                                return (
                                    <a className={"list-group-item list-group-item-action" + (item.key === activeTabID ? " active" : "")}
                                       href={"#" + item.key}
                                       key={item.key}
                                       onClick={(e) => {
                                           e.preventDefault();
                                           setActiveTabID(item.key)
                                       }}
                                    >
                                        {item.title}
                                    </a>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className="col-md-9 col-xl-10">
                    <div className="tab-content">
                        <TabCustomSettings tabActiveID={activeTabID} zoneID={zone_id}/>
                        {/*<TabCustomSettings tabActiveID={activeTabID} zoneID={zone_id}/>*/}
                    </div>
                </div>
            </div>

            <ZoneInfo zone_id={zone_id} />
        </Container>
    )
}
