import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";

import Container from "../../components/Container";
import Card from "../../components/Сard";
import Table from "../../components/table/Table";
import TableParams from "./Report1ListTableParams";
import ajax from "../../../data/ajax";
import {SelectDataFormatter, UrlCreator} from "../../../components/select_data";
import {consoleLogResponse} from "../../../components/development";
import LoadingWrapper from "../../components/LoadingWrapper";
import ButtonFilter from "../../components/ButtonFilter";
import SelectMulti from "../../components/select/SelectMulti";
import {useSelect} from "../../../components/input-hook";
import Filter from "../../../components/filter";
import TableColumns from "../../components/table/TableColumns";
import {ArrayIntersection, ArrayRemoveValues, ObjectRemoveEmptyForUrl} from "../../../components/helpers";
import BackendRequest from "../../../data/BackendRequest";
import DateRangePicker from "../../components/DateRangePicker";
import GroupByParams from "./Report1ListGroupByParams";
import FilterGroupByMulti from "../../components/FilterGroupByMulti";

const Report1List = ({user}) => {
    const location = useLocation();
    const locationPathname = location.pathname;

    const navigate = useNavigate();

    const [isLoadedFilterData, setIsLoadedFilterData] = useState(false);

    const localStorageTableKey = 'table_report_1_list';
    const [tableParams] = TableParams();

    const defaultHiddenColumns = [];
    const [tableGroupHiddenColumns, setTableGroupHiddenColumns] = useState([]);
    const [tableUserHiddenColumns, setUserTableHiddenColumns] = useState();
    const [tableHiddenColumns, setTableHiddenColumns] = useState([]);
    useEffect(() => {
        setTableHiddenColumns(defaultHiddenColumns.concat(tableGroupHiddenColumns, tableUserHiddenColumns))
    }, [tableGroupHiddenColumns, tableUserHiddenColumns]); // eslint-disable-line react-hooks/exhaustive-deps

    const groupByParams = GroupByParams(user);
    const [filterGroupBy, setFilterGroupBy] = useState([groupByParams.keyDate]);

    const [calendarDates, setCalendarDates] = useState({
        start: moment().subtract(14, 'days').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    });

    const [filterButton, setFilterButton] = useState(0);

    const inputFilterNetwork = useSelect([]);
    const inputFilterAccount = useSelect([]);
    const inputFilterManager = useSelect([]);
    const inputFilterFullDomain = useSelect([]);
    const inputFilterDomain = useSelect([]);
    const inputFilterPath = useSelect([]);
    const inputFilterPathRedirect = useSelect([]);
    const inputFilterPathReal = useSelect([]);

    const [dataRequestParams, setDataRequestParams] = useState({});
    const [{data, isLoading}, setUrlParams] = BackendRequest('reports/report_1', e => {
        e.cost = Number(e.cost);
        return e;
    });

    useEffect(() => {
        const fetchData = async () => {
            const requestConfig = ajax.getBaseConfig();
            const promises = [
                axios.get(UrlCreator.networks({'type': 'advertiser'}), requestConfig),
                axios.get(UrlCreator.accounts({'type': 'advertiser'}), requestConfig),
                axios.get(UrlCreator._custom('reports/report_1/filters'), requestConfig),
            ];
            Promise.all(promises).then(function (results) {
                consoleLogResponse(results);
                const [rNetworks, rAccounts, rFilters] = results;

                let filters = [
                    {
                        key: 'networks',
                        data: SelectDataFormatter.networks(rNetworks.data),
                        input: inputFilterNetwork,
                    },
                    {
                        key: 'accounts',
                        data: SelectDataFormatter.accounts(rAccounts.data),
                        input: inputFilterAccount,
                    },
                    {
                        key: 'domains',
                        data: SelectDataFormatter.value(rFilters.data.main_domain),
                        input: inputFilterDomain,
                    },
                    {
                        key: 'full_domains',
                        data: SelectDataFormatter.value(rFilters.data.domain),
                        input: inputFilterFullDomain,
                    },
                    {
                        key: 'paths',
                        data: SelectDataFormatter.valueWithEmpty(rFilters.data.path),
                        input: inputFilterPath,
                    },
                    {
                        key: 'path_redirects',
                        data: SelectDataFormatter.valueWithEmpty(rFilters.data.l_path),
                        input: inputFilterPathRedirect,
                    },
                    {
                        key: 'path_reals',
                        data: SelectDataFormatter.valueWithEmpty(rFilters.data.real_path),
                        input: inputFilterPathReal,
                    },
                ];
                if (user.allowManagers()) {
                    filters.push({
                        key: 'managers',
                        data: SelectDataFormatter.managers(user.getManagers()),
                        input: inputFilterManager,
                    });
                }

                let params = Filter.collectParamsForFilter(location, filters)

                const searchParamsObj = new URLSearchParams(location.search)
                const searchParams = Object.fromEntries(searchParamsObj);
                if (("group_by" in searchParams)) {
                    let allowedGroupBy = ArrayIntersection(searchParams.group_by.split(','), groupByParams.items.map(e => e.key))
                    params.group_by = allowedGroupBy.join(',');
                    setFilterGroupBy(allowedGroupBy);
                } else {
                    params.group_by = filterGroupBy.join(',');
                }

                params.date_from = ("date_from" in searchParams) ? searchParams.date_from : calendarDates.start;
                params.date_to = ("date_to" in searchParams) ? searchParams.date_to : calendarDates.end;
                setCalendarDates({
                    start: params.date_from,
                    end: params.date_to,
                });

                setIsLoadedFilterData(true);
            });
        };

        if (!isLoadedFilterData) {
            fetchData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const groupChangedColumns = [
            tableParams.keyDate,
            tableParams.keyCampaignId,
            tableParams.keyCampaignOriginId,
            tableParams.keyCampaignName,
            tableParams.keyNetworkId,
            tableParams.keyNetworkName,
            tableParams.keyAccountId,
            tableParams.keyAccountName,
            tableParams.keyManagerId,
            tableParams.keyManagerName,
            tableParams.keyDomain,
            tableParams.keyDomainMain,
            tableParams.keyPath,
            tableParams.keyPathL,
            tableParams.keyPathReal,
        ]

        filterGroupBy.forEach(e => {
            // eslint-disable-next-line default-case
            switch (e) {
                case groupByParams.keyDate:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyDate));
                    break;
                case groupByParams.keyCampaign:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns,
                        tableParams.keyCampaignId, tableParams.keyCampaignOriginId, tableParams.keyCampaignName,
                        // tableParams.keyDomain, tableParams.keyDomainMain, tableParams.keyPath, tableParams.keyPathL, tableParams.keyPathReal,
                    ));
                    break;
                case groupByParams.keyNetwork:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyNetworkId, tableParams.keyNetworkName));
                    break;
                case groupByParams.keyAccount:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyAccountId, tableParams.keyAccountName));
                    break;
                case groupByParams.keyManager:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyManagerId, tableParams.keyManagerName));
                    break;
                case groupByParams.keyDomain:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyDomain));
                    break;
                case groupByParams.keyDomainMain:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyDomainMain));
                    break;
                case groupByParams.keyPath:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyPath));
                    break;
                case groupByParams.keyPathL:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyPathL));
                    break;
                case groupByParams.keyPathReal:
                    setTableGroupHiddenColumns(ArrayRemoveValues(groupChangedColumns, tableParams.keyPathReal));
                    break;
            }
        })

    }, [filterGroupBy]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoadedFilterData) {
            setDataRequestParams({
                date_from: calendarDates.start,
                date_to: calendarDates.end,
                networks: inputFilterNetwork.value,
                accounts: inputFilterAccount.value,
                managers: inputFilterManager.value,
                domains: inputFilterDomain.value,
                full_domains: inputFilterFullDomain.value,
                paths: inputFilterPath.value,
                path_redirects: inputFilterPathRedirect.value,
                path_reals: inputFilterPathReal.value,
                group_by: filterGroupBy.join(','),
            })
        }
    }, [
        isLoadedFilterData,
        filterGroupBy,
        calendarDates,
        inputFilterNetwork.value,
        inputFilterAccount.value,
        inputFilterManager.value,
        inputFilterDomain.value,
        inputFilterFullDomain.value,
        inputFilterPath.value,
        inputFilterPathRedirect.value,
        inputFilterPathReal.value,
        filterButton
    ]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Object.keys(dataRequestParams).length > 0) {
            navigate(locationPathname + '?' + (new URLSearchParams(ObjectRemoveEmptyForUrl(dataRequestParams))).toString());
            setUrlParams(dataRequestParams);
        }
    }, [dataRequestParams]);

    return (
        <Container title={"Report landings"}>
            <LoadingWrapper isLoaded={isLoadedFilterData}>
                <Card>
                    <div className="row">
                        <div className="col-md-2">
                            <b>Date</b>
                            <DateRangePicker
                                dates={calendarDates}
                                updateDates={v => setCalendarDates(v)}
                            />
                        </div>
                        <div className="col-md-2">
                            <b>Select network</b>
                            <SelectMulti {...inputFilterNetwork.bind}/>
                        </div>
                        <div className="col-md-2">
                            <b>Select account</b>
                            <SelectMulti {...inputFilterAccount.bind}/>
                        </div>
                        <div className="col-md-2">{
                            user.allowManagers() && <>
                                <b>Select manager</b>
                                <SelectMulti {...inputFilterManager.bind}/>
                            </>
                        }</div>
                        <div className="col-md-2">&nbsp;</div>
                        <div className="col-md-2">&nbsp;</div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <b>Select full domain</b>
                            <SelectMulti {...inputFilterFullDomain.bind}/>
                        </div>
                        <div className="col-md-2">
                            <b>Select domain</b>
                            <SelectMulti {...inputFilterDomain.bind}/>
                        </div>
                        <div className="col-md-2">
                            <b>Select path</b>
                            <SelectMulti {...inputFilterPath.bind}/>
                        </div>
                        <div className="col-md-2">
                            <b>Select path redirect</b>
                            <SelectMulti {...inputFilterPathRedirect.bind}/>
                        </div>
                        <div className="col-md-2">
                            <b>Select path real</b>
                            <SelectMulti {...inputFilterPathReal.bind}/>
                        </div>
                        <div className="col-md-2">&nbsp;</div>
                    </div>
                    <div className="row mt-3">
                    <div className="col-md-3">
                            <ButtonFilter setFilterButton={v => setFilterButton(v)}/>
                        </div>
                        <div className="col-md-6">&nbsp;</div>
                        <div className="col-md-3 text-right">
                            <TableColumns
                                storageKey={localStorageTableKey}
                                columns={tableParams.columns}
                                setUserTableHiddenColumns={v => setUserTableHiddenColumns(v)}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <FilterGroupByMulti
                                items={groupByParams.items}
                                value={filterGroupBy}
                                updateValue={v => setFilterGroupBy(v)}
                            />
                        </div>
                    </div>
                </Card>
            </LoadingWrapper>

            <LoadingWrapper isLoaded={isLoadedFilterData && !isLoading}>
                <Card>
                    <Table
                        data={data}
                        {...tableParams}
                        hiddenColumns={tableHiddenColumns}
                    />
                </Card>
            </LoadingWrapper>
        </Container>
    );
}

export default Report1List;
