import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";

import Container from "../../components/Container";
import Card from "../../components/Сard";
import Table from "../../components/table/Table";
import TableParams from "./PostbacksListTableParams";
import ajax from "../../../data/ajax";
import {SelectDataFormatter, UrlCreator} from "../../../components/select_data";
import {consoleLogResponse} from "../../../components/development";
import LoadingWrapper from "../../components/LoadingWrapper";
import ButtonFilter from "../../components/ButtonFilter";
import SelectMulti from "../../components/select/SelectMulti";
import {useSelect} from "../../../components/input-hook";
import Filter from "../../../components/filter";
import TableColumns from "../../components/table/TableColumns";
import {ObjectRemoveEmptyForUrl} from "../../../components/helpers";
import BackendRequest from "../../../data/BackendRequest";
import DateRangePicker from "../../components/DateRangePicker";

const PostbacksList = ({user}) => {
    // DATE / ZONE / GEO / CLICK_ID / SENDED

    const location = useLocation();
    const locationPathname = location.pathname;

    const navigate = useNavigate();

    const [isLoadedFilterData, setIsLoadedFilterData] = useState(false);

    const localStorageTableKey = 'table_postbacks_list';
    const [tableParams] = TableParams();

    const [tableGroupHiddenColumns] = useState([]);
    const [tableUserHiddenColumns, setUserTableHiddenColumns] = useState();
    const [tableHiddenColumns, setTableHiddenColumns] = useState([]);
    useEffect(() => {
        setTableHiddenColumns(tableGroupHiddenColumns.concat(tableUserHiddenColumns))
    }, [tableGroupHiddenColumns, tableUserHiddenColumns]); // eslint-disable-line react-hooks/exhaustive-deps

    const [filterButton, setFilterButton] = useState(0);

    const [calendarDates, setCalendarDates] = useState({
        start: moment().subtract(6, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
    });

    const inputFilterAccount = useSelect([]);
    const inputFilterCountry = useSelect([]);
    const inputFilterPubAccount = useSelect([]);
    const inputFilterSite = useSelect([]);
    const inputFilterDirection = useSelect([]);
    const inputFilterAvailable = useSelect([]);
    const inputFilterManager = useSelect([]);
    const inputFilterZone = useSelect([]);
    const inputFilterRejectReasons = useSelect([]);

    const [dataRequestParams, setDataRequestParams] = useState({});
    const [{data, isLoading}, setUrlParams] = BackendRequest('postbacks/list');

    useEffect(() => {
        const fetchData = async () => {
            const requestConfig = ajax.getBaseConfig();
            const promises = [
                axios.get(UrlCreator.accounts({'type': 'advertiser'}), requestConfig),
                axios.get(UrlCreator.accounts({'type': 'publisher', 'type_id': 9}), requestConfig),
                axios.get(UrlCreator.sites(), ajax.getBaseConfig()),
                axios.get(UrlCreator.postbackZones(), requestConfig),
                axios.get(UrlCreator.postbackRejectStatuses(), requestConfig),
            ];
            Promise.all(promises).then(function (results) {
                consoleLogResponse(results);
                const [rAccountsAdv, rAccountsPub, rSites, rZones, rReasons] = results;

                let filters = [
                    {
                        key: 'accounts',
                        data: SelectDataFormatter.accounts(rAccountsAdv.data),
                        input: inputFilterAccount,
                    },
                    {
                        key: 'pub_accounts',
                        data: SelectDataFormatter.accounts(rAccountsPub.data),
                        input: inputFilterPubAccount,
                    },
                    {
                        key: 'origin_site_id',
                        data: SelectDataFormatter.sites(rSites.data),
                        input: inputFilterSite,
                    },
                    {
                        key: 'direction',
                        data: SelectDataFormatter.directions(),
                        input: inputFilterDirection,
                    },
                    {
                        key: 'is_available',
                        data: SelectDataFormatter.bool(),
                        input: inputFilterAvailable,
                    },
                    {
                        key: 'managers',
                        data: SelectDataFormatter.managers(user.getManagers()),
                        input: inputFilterManager,
                    },
                    {
                        key: 'country',
                        data: SelectDataFormatter.countries(user.getCountries()),
                        input: inputFilterCountry,
                    },
                    {
                        key: 'zone',
                        data: SelectDataFormatter.zones2(rZones.data),
                        input: inputFilterZone,
                    },
                    {
                        key: 'reject_reason',
                        data: SelectDataFormatter.valueWithEmpty(rReasons.data),
                        input: inputFilterRejectReasons,
                    },
                ];

                let params = Filter.collectParamsForFilter(location, filters)

                const searchParamsObj = new URLSearchParams(location.search)
                const searchParams = Object.fromEntries(searchParamsObj);

                params.date_from = ("date_from" in searchParams) ? searchParams.date_from : calendarDates.start;
                params.date_to = ("date_to" in searchParams) ? searchParams.date_to : calendarDates.end;
                setCalendarDates({
                    start: params.date_from,
                    end: params.date_to,
                });

                setIsLoadedFilterData(true);
            });
        };

        if (!isLoadedFilterData) {
            fetchData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoadedFilterData) {
            setDataRequestParams({
                date_from: calendarDates.start,
                date_to: calendarDates.end,
                country: inputFilterCountry.value,
                zone: inputFilterZone.value,
                reject_reason: inputFilterRejectReasons.value,

                accounts: inputFilterAccount.value,
                pub_accounts: inputFilterPubAccount.value,
                origin_site_id: inputFilterSite.value,
                direction: inputFilterDirection.value,
                is_available: inputFilterAvailable.value,
                managers: inputFilterManager.value,
            })
        }
    }, [
        isLoadedFilterData,
        calendarDates,
        inputFilterCountry.value,
        inputFilterZone.value,
        inputFilterRejectReasons.value,
        inputFilterAccount.value,
        inputFilterPubAccount.value,
        inputFilterSite.value,
        inputFilterDirection.value,
        inputFilterAvailable.value,
        inputFilterManager.value,
        filterButton
    ]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Object.keys(dataRequestParams).length > 0) {
            navigate(locationPathname + '?' + (new URLSearchParams(ObjectRemoveEmptyForUrl(dataRequestParams))).toString());
            setUrlParams(dataRequestParams);
        }
    }, [dataRequestParams]);

    return (
        <Container title={"Postbacks List (latest 1000)"}>
            <LoadingWrapper isLoaded={isLoadedFilterData}>
                <Card>
                    <div className="row">
                        <div className="col-md-3">
                            <b>Date</b>
                            <DateRangePicker
                                dates={calendarDates}
                                updateDates={v => setCalendarDates(v)}
                            />
                        </div>
                        <div className="col-md-3">
                            <b>Select country</b>
                            <SelectMulti {...inputFilterCountry.bind}/>
                        </div>
                        <div className="col-md-3">
                            <b>Select zone</b>
                            <SelectMulti {...inputFilterZone.bind}/>
                        </div>
                        <div className="col-md-3">
                            <b>Select reject status</b>
                            <SelectMulti {...inputFilterRejectReasons.bind}/>
                        </div>
                        {/*                        <div className="col-md-4">
                            <b>Select pub account</b>
                            <SelectMulti {...inputFilterPubAccount.bind}/>
                        </div>
                        <div className="col-md-4">
                            <b>Select site</b>
                            <SelectMulti {...inputFilterSite.bind}/>
                        </div>
                        <div className="col-md-4">
                            <b>Select direction</b>
                            <SelectMulti {...inputFilterDirection.bind}/>
                        </div>*/}
                    </div>
                    {/*                    <div className="row">
                        <div className="col-md-4">
                            <b>Select account</b>
                            <SelectMulti {...inputFilterAccount.bind}/>
                        </div>
                        <div className="col-md-4">
                            <b>Select manager</b>
                            <SelectMulti {...inputFilterManager.bind}/>
                        </div>
                        <div className="col-md-4">
                            <b>Select available</b>
                            <SelectMulti {...inputFilterAvailable.bind}/>
                        </div>
                    </div>*/}
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <ButtonFilter setFilterButton={v => setFilterButton(v)}/>
                        </div>
                        <div className="col-md-6">&nbsp;</div>
                        <div className="col-md-3 text-right">
                            <TableColumns
                                storageKey={localStorageTableKey}
                                columns={tableParams.columns}
                                setUserTableHiddenColumns={v => setUserTableHiddenColumns(v)}
                            />
                        </div>
                    </div>
                </Card>
            </LoadingWrapper>

            <LoadingWrapper isLoaded={isLoadedFilterData && !isLoading}>
                <Card>
                    <Table
                        data={data}
                        {...tableParams}
                        hiddenColumns={tableHiddenColumns}
                    />
                </Card>
            </LoadingWrapper>
        </Container>
    );
}

export default PostbacksList;
