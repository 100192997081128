import React, {useState} from 'react';
import {useParams} from "react-router-dom";

import Container from "../../../components/Container";

export default function ExStatMappingForm() {
    let {account_id} = useParams();
    const [activeTabID, setActiveTabID] = useState('add');

    const menuItems = [
        {key: 'add', title: 'Add'},
        // {key: 'info', title: 'Info'},
    ];

    return (
        <Container title={"Account " + account_id}>
            <div className="row">
                <div className="col-md-3 col-xl-2">
                    <div className="card">
                        <div className="list-group list-group-flush" role="tablist">
                            {menuItems.map((item) => {
                                return (
                                    <a className={"list-group-item list-group-item-action" + (item.key === activeTabID ? " active" : "")}
                                       href={"#" + item.key}
                                       key={item.key}
                                       onClick={(e) => {
                                           e.preventDefault();
                                           setActiveTabID(item.key)
                                       }}
                                    >
                                        {item.title}
                                    </a>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className="col-md-9 col-xl-10">
                    <div className="tab-content">
                        {/*<TabMain tabActiveID={activeTabID} zoneID={account_id}/>*/}
                    </div>
                </div>
            </div>
        </Container>
    )
}
