import React, {useEffect, useState} from 'react';
import {Route, Routes, Navigate } from "react-router-dom"

import axios from "axios";
import * as Icon from "react-feather";

import Footer from "./views/Footer";
import Header from "./views/Header";
import Sidebar from "./views/Sidebar";
import Dashboard from "./views/pages/Dashboard";
import Offer from "./views/pages/Offer";
import Offers from "./views/pages/Offers";
import TrafficSources from "./views/pages/TrafficSources";
import Stats from "./views/pages/Stats";
import Settings from "./views/pages/Settings";
import Lss from "./views/pages/Lss";
import Terms from "./views/pages/static/Terms";
import Privacy from "./views/pages/static/Privacy";
import Help from "./views/pages/static/Help";
import Support from "./views/pages/static/Support";
import ajax from "./data/ajax";
import Loading from "./views/components/Loading";
import ScrollToTop from "./views/components/ScrollToTop";
import Login from "./views/pages/Login";
import Payments from "./views/pages/Payments";
import Logout from "./views/pages/Logout";
import Develop from "./views/pages/Develop";
import Rates from "./views/pages/Rates";
import RegisterRecaptcha from "./views/pages/RegisterRecaptcha";
import Empty from "./views/pages/Empty";
import ExStatZoneList from "./views/pages/ExStat/Zone/List";
import ExStatZoneEditForm from "./views/pages/ExStat/Zone/EditForm";
import SidebarItemsLss from "./views/SidebarItemsLss";
import ExStatCampaignList from "./views/pages/ExStat/Campaign/List";
import ExStatMappingForm from "./views/pages/ExStat/Mapping/Form";
import SegmentRatesList from "./views/pages/SegmentRates/List";
import SegmentDefaultsList from "./views/pages/SegmentDefaults/List";
import SegmentDefaultsEditForm from "./views/pages/SegmentDefaults/EditForm";
import ExStatDashboardList from "./views/pages/ExStat/Dashboard1/List";
import ExStatDashboard2List from "./views/pages/ExStat/Dashboard2/List";
import ExStatDashboard3List from "./views/pages/ExStat/Dashboard3/List";
import ExStatDashboard4List from "./views/pages/ExStat/Dashboard4/List";
import ExStatDashboardTotalList from "./views/pages/ExStat/DashboardTotal/List";
import NftCollectionList from "./views/pages/Nft/Collection/List";
import NftTokenList from "./views/pages/Nft/Token/List";
import {allowAdminNftOptions, allowAdminOptions, allowClickaduDashboard} from "./components/admins_list";
import NftCollectionAddForm from "./views/pages/Nft/Collection/AddForm";
import ExStatCampaignClickaduCreateForm from "./views/pages/ExStat/Campaign/Clickadu/ExStatCampaignClickaduCreateForm";
import ExStatCampaignItem from "./views/pages/ExStat/Campaign/Item";
import ExStatCampaignClickaduEditForm from "./views/pages/ExStat/Campaign/Clickadu/ExStatCampaignClickaduEditForm";
import ExStatPlacementEditForm from "./views/pages/ExStat/Placement/EditForm";
import {UrlCreator} from "./components/select_data";
import PostbackSetupZonesList from "./views/pages/PostbackSetup/PostbackSetupZonesList";
import ZoneListPropush from "./views/pages/ZonePropush/ZoneList";
import ZoneListRollerads from "./views/pages/ZoneRollerads/ZoneList";
import PostbackSetupZonesFormEdit from "./views/pages/PostbackSetup/PostbackSetupZonesFormEdit";
import PostbackSetupZonesFormCreate from "./views/pages/PostbackSetup/PostbackSetupZonesFormCreate";
import PropushEditForm from "./views/pages/ZonePropush/PropushEditForm";
import PropushCreateForm from "./views/pages/ZonePropush/PropushCreateForm";
import PostbackSetupDemo from "./views/pages/PostbackSetup/PostbackSetupDemo";
import PostbackSetupCustomList from "./views/pages/PostbackSetup/PostbackSetupCustomList";
import PostbackSetupCustomFormCreate from "./views/pages/PostbackSetup/PostbackSetupCustomFormCreate";
import PostbackSetupCustomFormEdit from "./views/pages/PostbackSetup/PostbackSetupCustomFormEdit";
import PropushAssignForm from "./views/pages/ZonePropush/PropushAssignForm";
import DataValidationList from "./views/pages/DataValidation/DataValidationList";
import DataValidationSummaryList from "./views/pages/DataValidation/DataValidationSummaryList";
import Parent from "./views/Parent";
import Page404 from "./views/pages/Page404";
import DomainsList from "./views/pages/Domains/List";
import LandingLinkPage from "./views/pages/LandingLink/LandingLinkPage";
import ZoneZeydooList from "./views/pages/ZoneZeydoo/ZoneZeydooList";
import PostbacksList from "./views/pages/Postbacks/PostbacksList";
import Report1List from "./views/pages/Report1/Report1List";

const App = props => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [error] = useState(null);
    const [user, setUser] = useState([]);

    const [menuItems, setMenuItems] = useState([]);
    const [userName, setUserName] = useState("");
    const [balances] = useState({});
    const [codes] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const requestConfig = ajax.getBaseConfig();

            axios.get(window.trackerBaseUrl + "auth", requestConfig).then(response => {
                // console.log("response", response)
                if (response.data === null) {
                    setIsLoaded(true);
                    setIsAuth(false);
                } else {
                    setIsAuth(true);
                    const u = response.data;
                    u.allowManagers = () => u.app_data.managers.length > 1;
                    u.getManagers = () => u.app_data.managers;
                    u.getCountries = () => u.app_data.countries;
                    setUser(u);

                    let user = response.data;

                    let uName = (response.data.first_name + " " + response.data.last_name).trim();
                    uName = uName.length > 0 ? uName : response.data.email
                    setUserName(uName);

                    const promises = [];
                    if (process.env.REACT_APP_TYPE === "cabinet") {
                        promises.push(axios.get(UrlCreator.accounts(), requestConfig));
                    }
                    Promise.all(promises).then(function (results) {
                        let mItems = [];
                        if (process.env.REACT_APP_TYPE === "lss") {
                            mItems = SidebarItemsLss();
                        } else if (process.env.REACT_APP_TYPE === "cabinet") {
                            // console.log("user -----------------", user);
                            // if (adminsIdList.includes(user.id)) {
                                mItems.push({
                                    title: 'Dashboard Push',
                                    link: 'dashboard/push',
                                    icon: <Icon.TrendingUp/>,
                                })
                                mItems.push({
                                    title: 'Dashboard TB',
                                    link: 'dashboard/tb',
                                    icon: <Icon.TrendingUp/>,
                                })
                            // }

                            if (allowClickaduDashboard(user.id)) {
                                mItems.push({
                                    title: 'Dashboard Clickadu',
                                    link: 'dashboard/clickadu',
                                    icon: <Icon.TrendingUp/>,
                                })
                            }

                            mItems.push({
                                title: 'Dashboard Zeydoo',
                                link: 'dashboard/zeydoo',
                                icon: <Icon.TrendingUp/>,
                            })

                            if (allowAdminOptions(user.id)) {
                                mItems.push({
                                    title: 'Dashboard Total',
                                    link: 'dashboard/total',
                                    icon: <Icon.TrendingUp/>,
                                })
                            }

                            if (allowAdminOptions(user.id)) {
                                mItems.push({
                                    title: 'Data validation Summary',
                                    link: 'data-validation/summary',
                                    icon: <Icon.CheckSquare/>,
                                })
                            }
                            mItems.push({
                                title: 'Data validation',
                                link: 'data-validation/by-account?latest=1&valid=0',
                                icon: <Icon.CheckSquare/>,
                            })

                            results[0].data.forEach((item) => {
                                mItems.push({
                                    // title: 'Source: ' + item.name,
                                    title: item.name,
                                    link: 'ex-stat/account/' + item.id,
                                    icon: <Icon.Sliders/>,
                                })

                                codes[item.id] = item.code;
                                balances[item.id] = item.balance;
                            });
                            mItems.push({
                                title: 'Landings',
                                link: 'collections/landings',
                                icon: <Icon.Layers/>,
                            })
                            mItems.push({
                                title: 'Domains',
                                link: 'collections/domains',
                                icon: <Icon.Layers/>,
                            })
                            mItems.push({
                                title: 'Campaigns',
                                link: 'ex-stat/campaign',
                                icon: <Icon.Layers/>,
                            })
                            mItems.push({
                                title: 'Zones (PropushMe)',
                                link: 'zone/propushme',
                                icon: <Icon.Layers/>,
                            })
                            mItems.push({
                                title: 'Zones (RollerAds)',
                                link: 'zone/rollerads',
                                icon: <Icon.Layers/>,
                            })
                            mItems.push({
                                title: 'Zones (Zeydoo)',
                                link: 'zone/zeydoo',
                                icon: <Icon.Layers/>,
                            })
                            mItems.push({
                                title: 'Postback Zones',
                                link: 'postback-setup/zones',
                                icon: <Icon.Layers/>,
                            })
                            mItems.push({
                                title: 'Postback Custom',
                                link: 'postback-setup/custom',
                                icon: <Icon.Layers/>,
                            })
                            mItems.push({
                                title: 'Postbacks',
                                link: 'postbacks',
                                icon: <Icon.Layers/>,
                            })
                            mItems.push({
                                title: 'Report 1',
                                link: 'reports/report_1',
                                icon: <Icon.FileText/>,
                            })

                            // console.log("user -----------------", user);
                            if (allowAdminOptions(user.id)) {
                                mItems.push({
                                    title: 'Rates',
                                    link: 'segment-rate',
                                    icon: <Icon.Layers/>,
                                })
                                mItems.push({
                                    title: 'Rates settings',
                                    link: 'segment-defaults',
                                    icon: <Icon.Layers/>,
                                })
                            }

                            if (allowAdminNftOptions(user.id)) {
                                mItems.push({
                                    title: 'NFT Collections',
                                    link: 'nft/collections',
                                    icon: <Icon.Archive/>,
                                })
                                mItems.push({
                                    title: 'NFT Tokens',
                                    link: 'nft/tokens',
                                    icon: <Icon.Image/>,
                                })
                            }
                        }
                        if (process.env.NODE_ENV === "development") {
                            mItems.push({
                                title: 'Development',
                                link: 'dev',
                                icon: <Icon.Command/>,
                            })
                        }
                        setMenuItems(mItems);

                        setIsLoaded(true);
                    });
                }
            })
        };

        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (error) {
        return <div>Ошибка: {error.message}</div>
    } else if (!isLoaded) {
        return <Loading/>
    } else if (!isAuth) {
        return (
            <React.Fragment>
                <Routes>
                    <Route path='/login' element={<Login/>}/>
                    <Route path='/signup' element={<RegisterRecaptcha/>}/>
                    <Route path="*" element={<Navigate to="/login"/>}/>
                </Routes>
            </React.Fragment>
        )
    } else {
        return (
            <div className="wrapper">
                <Sidebar menuItems={menuItems}/>

                <div className="main">
                    <Header userName={userName}/>

                    <main className="content">
                        <ScrollToTop/>
                        {
                            process.env.REACT_APP_TYPE === "lss" &&
                            <Routes>
                                <Route path='/stats' element={<Lss user={user}/>}/>
                                <Route path='/rates' element={<Rates/>}/>
                                <Route path='/settings' element={<Settings/>}/>
                                <Route path='/dev' element={<Develop/>}/>
                                <Route path='/logout' element={<Logout/>}/>
                                <Route path="/" element={<Navigate to="/stats"/>}/>
                            </Routes>
                        }

                        {
                            process.env.REACT_APP_TYPE === "cabinet" &&
                            <Routes>
                                <Route path="*" element={<Page404/>} />
                                <Route path="/" element={<Navigate to="/empty"/>}/>
                                <Route path="/login" element={<Navigate to="/empty"/>}/>
                                <Route path="/dashboard" element={<Parent/>}>
                                    <Route path="push" element={<ExStatDashboardList user={user}/>}/>
                                    <Route path="tb" element={<ExStatDashboard2List user={user}/>}/>
                                    <Route path="clickadu" element={<ExStatDashboard3List user={user}/>}/>
                                    <Route path="zeydoo" element={<ExStatDashboard4List user={user}/>}/>
                                    <Route path="total" element={<ExStatDashboardTotalList user={user}/>}/>
                                </Route>
                                <Route path="/data-validation" element={<Parent/>}>
                                    <Route path="summary" element={<DataValidationSummaryList user={user}/>}/>
                                    <Route path="by-account" element={<DataValidationList user={user}/>}/>
                                </Route>
                                <Route path="/collections" element={<Parent/>}>
                                    <Route path="landings" element={<LandingLinkPage user={user}/>}/>
                                    <Route path="domains" element={<DomainsList user={user}/>}/>
                                </Route>
                                <Route path="/postbacks" element={<PostbacksList user={user}/>}/>
                                <Route path="/ex-stat/campaign/create" element={<ExStatCampaignClickaduCreateForm user={user}/>}/>
                                <Route path="/ex-stat/campaign/:campaign_id/placement/:id/edit" element={<ExStatPlacementEditForm user={user}/>}/>
                                <Route path="/ex-stat/campaign/:id/edit" element={<ExStatCampaignClickaduEditForm user={user}/>}/>
                                <Route path="/ex-stat/campaign/:id" element={<ExStatCampaignItem user={user}/>}/>
                                <Route path="/ex-stat/campaign" element={<ExStatCampaignList user={user}/>}/>
                                <Route path="/ex-stat/account/:account_id" element={<ExStatZoneList user={user} balances={balances} codes={codes}/>}/>
                                <Route path="/ex-stat/zone/assign/:zone_id" element={<PropushAssignForm user={user}/>}/>
                                <Route path="/ex-stat/zone/edit/:zone_id" element={<ExStatZoneEditForm user={user}/>}/>
                                <Route path="/ex-stat/zone/edit-propush/:zone_id" element={<PropushEditForm user={user}/>}/>
                                <Route path="/ex-stat/zone/create" element={<PropushCreateForm user={user}/>}/>
                                <Route path="/ex-stat/mapping/add/:account_id" element={<ExStatMappingForm user={user}/>}/>
                                <Route path="/zone" element={<Parent/>}>
                                    <Route path="propushme" element={<ZoneListPropush user={user}/>}/>
                                    <Route path="rollerads" element={<ZoneListRollerads user={user}/>}/>
                                    <Route path="zeydoo" element={<ZoneZeydooList user={user}/>}/>
                                </Route>
                                <Route path="/postback-setup/demo" element={<PostbackSetupDemo user={user}/>}/>
                                <Route path="/postback-setup/zones/create/:zone_id" element={<PostbackSetupZonesFormCreate user={user}/>}/>
                                <Route path="/postback-setup/zones/edit/:id" element={<PostbackSetupZonesFormEdit user={user}/>}/>
                                <Route path="/postback-setup/zones" element={<PostbackSetupZonesList user={user}/>}/>
                                <Route path="/postback-setup/custom/edit/:id" element={<PostbackSetupCustomFormEdit user={user}/>}/>
                                <Route path="/postback-setup/custom/create" element={<PostbackSetupCustomFormCreate user={user}/>}/>
                                <Route path="/postback-setup/custom" element={<PostbackSetupCustomList user={user}/>}/>
                                <Route path="/reports" element={<Parent/>}>
                                    <Route path="report_1" element={<Report1List user={user}/>}/>
                                </Route>

                                {/* adminsIdList.includes(user.id)*/}

                                <Route path="/segment-rate/" element={<SegmentRatesList {...props} user={user}/>}/>
                                <Route path="/segment-defaults/edit/:segment_id" element={<SegmentDefaultsEditForm user={user}/>}/>
                                <Route path="/segment-defaults/" element={<SegmentDefaultsList user={user}/>}/>
                                {
                                    allowAdminNftOptions(user.id) &&
                                    <Route path="/nft/collection/add/" element={<NftCollectionAddForm user={user}/>}/>
                                }
                                {
                                    allowAdminNftOptions(user.id) &&
                                    <Route path="/nft/collections/" element={<NftCollectionList user={user}/>}/>
                                }
                                {
                                    allowAdminNftOptions(user.id) &&
                                    <Route path="/nft/tokens/" element={<NftTokenList user={user}/>}/>
                                }

                                {/* @TODO clean old routes with backend controllers */}
                                <Route path='/dashboard_old' element={<Dashboard/>}/>
                                <Route path='/offers' element={<Offers/>}/>
                                <Route path='/offer/:offerId' element={<Offer/>}/>
                                <Route path='/traffic-sources' element={<TrafficSources/>}/>
                                <Route path='/payments' element={<Payments/>}/>
                                <Route path='/stats' element={<Stats/>}/>
                                <Route path='/settings' element={<Settings/>}/>
                                <Route path='/terms' element={<Terms/>}/>
                                <Route path='/privacy' element={<Privacy/>}/>
                                <Route path='/help' element={<Help/>}/>
                                <Route path='/support' element={<Support/>}/>

                                <Route path='/stats' element={<Lss/>}/>
                                <Route path='/rates' element={<Rates/>}/>

                                <Route path='/empty' element={<Empty/>}/>
                                <Route path='/dev' element={<Develop/>}/>
                                <Route path='/logout' element={<Logout/>}/>

                                {/*DEPRECATED ROUTES*/}
                                <Route path="/ex-stat/dashboard" element={<ExStatDashboardList user={user}/>}/>
                                <Route path="/ex-stat/dashboard_tb" element={<ExStatDashboard2List user={user}/>}/>
                                <Route path="/ex-stat/dashboard_clickadu" element={<ExStatDashboard3List user={user}/>}/>
                            </Routes>
                        }
                    </main>

                    <Footer/>
                </div>
            </div>
        );
    }
}

export default App;
