import React from "react";

import {round} from "../../../../components/Math";

export default function ExStatDashboardTableParams() {
    const keyDate = 'date';

    const columns = React.useMemo(
        () => {
            let calcCost = (rows) => rows.reduce((sum, row) => Number(row.values.total_cost ?? 0) + sum, 0)
            let calcRevenue = (rows) => rows.reduce((sum, row) => Number(row.values.total_revenue ?? 0) + sum, 0)
            let calcProfit = (rows) => calcRevenue(rows) - calcCost(rows)
            let calcRoi = (rows) => calcProfit(rows) / calcCost(rows)

            return [
                {
                    Header: 'Date',
                    accessor: keyDate,
                    Footer: <b>Total:</b>,
                },
                {
                    Header: 'Total Cost, $',
                    accessor: 'total_cost',
                    Cell: ({row}) => <>{round(row.original.total_cost, 0)}</>,
                    Footer: info => <>{round(calcCost(info.rows), 0)}</>,
                },
                {
                    Header: 'Total Revenue, $',
                    accessor: 'total_revenue',
                    Cell: ({row}) => <>{round(row.original.total_revenue, 0)}</>,
                    Footer: info => <>{round(calcRevenue(info.rows), 0)}</>,
                },
                {
                    // Header: 'Profit + TB, $',
                    Header: 'Total Profit, $',
                    sortType: 'basic',
                    Cell: ({row}) => <>{round((row.original.total_revenue - row.original.total_cost), 0)}</>,
                    Footer: info => <>{round(calcProfit(info.rows), 0)}</>,
                },
                {
                    Header: 'Total ROI, %',
                    sortType: 'basic',
                    Cell: ({row}) => <>{round(100 * (row.original.total_revenue - row.original.total_cost) / row.original.total_cost, 1)}</>,
                    Footer: info => <>{round(100 * calcRoi(info.rows), 1)}</>,
                },
            ]
        }, []
    );

    const initialState = {
        sortBy: [
            {
                id: keyDate,
                desc: true
            }
        ]
    }

    return {
        keyDate, columns, initialState
    };
}
