import {useState, useEffect} from 'react';

import ajax from "./ajax";
import axios from "axios";

import {consoleLogResponse} from "../components/development";

export default function DataExStatDashboard() {
    const [params, setParams] = useState({});
    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsError(false);
            setIsLoading(true);

            try {
                if (Object.keys(params).length > 0) {
                    const requestConfig =  ajax.getBaseConfig();
                    const urlParams = (new URLSearchParams(params)).toString();

                    const promises = [
                        axios.get(window.trackerBaseUrl + "ex-stat/dashboard?" + (new URLSearchParams(Object.assign({}, params, {"dashboard": 1}))).toString(), requestConfig),
                        axios.get(window.trackerBaseUrl + "ex-stat/dashboard?" + (new URLSearchParams(Object.assign({}, params, {"dashboard": 4}))).toString(), requestConfig),
                        axios.get(window.trackerBaseUrl + "ex-stat/dashboard2?" + urlParams, requestConfig),
                        axios.get(window.trackerBaseUrl + "ex-stat/dashboard3?" + urlParams, requestConfig),
                    ];
                    Promise.all(promises).then(results => {
                        consoleLogResponse(results);

                        let data = {};

                        const empty = {
                            total_cost: 0,
                            total_revenue: 0,
                        };

                        results[0].data.forEach(v => {
                            if (!(v.date in data)) {
                                data[v.date] = {...empty};
                                data[v.date].date = v.date;
                            }

                            data[v.date].total_cost = data[v.date].total_cost + Number(v.cost)
                            data[v.date].total_revenue = data[v.date].total_revenue + Number(v.revenue) + Number(v.revenue_tb)
                        });

                        results[1].data.forEach(v => {
                            if (!(v.date in data)) {
                                data[v.date] = {...empty};
                                data[v.date].date = v.date;
                            }

                            data[v.date].total_cost = data[v.date].total_cost + Number(v.cost)
                            data[v.date].total_revenue = data[v.date].total_revenue + Number(v.revenue) + Number(v.revenue_tb)
                        });

                        results[2].data.forEach(v => {
                            if (!(v.date in data)) {
                                data[v.date] = {...empty};
                                data[v.date].date = v.date;
                            }

                            data[v.date].total_cost = data[v.date].total_cost + Number(v.cost) + Number(v.cost_test)
                            data[v.date].total_revenue = data[v.date].total_revenue + Number(v.revenue_tb)
                        });

                        results[3].data.forEach(v => {
                            if (!(v.date in data)) {
                                data[v.date] = {...empty};
                                data[v.date].date = v.date;
                            }

                            data[v.date].total_cost = data[v.date].total_cost + Number(v.cost)
                            data[v.date].total_revenue = data[v.date].total_revenue + Number(v.revenue) + Number(v.revenue_tb)
                        });

                        data = Object.values(data);

                        // setIsLoaded(true);
                        setData(data);
                    });
                }
            } catch (error) {
                console.log('error', error)
                setIsError(true);
            }

            setIsLoading(false);
        };

        fetchData();
    }, [params]);

    return [{ data, isLoading, isError }, setParams];
}
