import React from "react";

export default function ListGroupByParams(user) {
    const keyDate = 'date';
    const keyManager = 'manager';
    const keyAccount = 'account';
    const keyPubAccount = 'pub_account';
    const keyCampaign = 'campaign';
    const keyZone = 'zone';
    const keyZoneTb = 'zone_tb';
    const keyCountry = 'country';
    const keyType = 'type';
    const keyDateYear = 'date_year';
    const keyDateMonth = 'date_month';
    const keyDateWeek = 'date_week';

    const items = React.useMemo(() => [
        {
            title: "Date",
            key: keyDate,
        },
        {
            title: "Manager",
            key: keyManager,
            visible: user.allowManagers(),
        },
        {
            title: "Account",
            key: keyAccount,
        },
        {
            title: "Pub Account",
            key: keyPubAccount,
        },
        {
            title: "Campaign",
            key: keyCampaign,
        },
        {
            title: "Zone",
            key: keyZone,
        },
        {
            title: "TB zone",
            key: keyZoneTb,
        },
        {
            title: "Country",
            key: keyCountry,
        },
        {
            title: "Type",
            key: keyType,
        },
        {
            title: "Year",
            key: keyDateYear,
        },
        {
            title: "Month",
            key: keyDateMonth,
        },
        {
            title: "Week",
            key: keyDateWeek,
        },
    ], []);

    return {
        keyDate, keyManager, keyAccount, keyPubAccount, keyCampaign,
        keyZone, keyZoneTb, keyCountry, keyType,
        keyDateYear, keyDateMonth, keyDateWeek,
        items
    };
}
