import React from "react";
import {Link} from "react-router-dom";
import * as Icon from "react-feather";

import ZoneData from "../../../components/ZoneData";

export default function PostbacksListTableParams() {
    const keyZoneId = 'zone_id';
    const keyOriginZoneId = 'origin_zone_id';

    const columns = React.useMemo(
        () => [
            {
                Header: 'Is ping',
                accessor: 'is_ping',
            },
            {
                Header: 'Date (UTC)',
                accessor: 'date',
            },
            {
                Header: 'Zone Id',
                accessor: 'income_zone_origin_id',
            },
            {
                Header: 'Geo',
                Cell: ({row}) => row.original['geo'].toUpperCase(),
                accessor: 'geo',
            },
            {
                Header: 'Postback Settings',
                Cell: ({row}) => row.original['income_postback_settings_id'] !== 0
                    ? <Link to={"/postback-setup/zones/edit/" + row.original['income_postback_settings_id']}>{row.original['income_postback_settings_id']}</Link>
                    : '',
                accessor: 'income_postback_settings_id',
            },
            {
                Header: 'PS version',
                accessor: 'income_postback_settings_version',
            },
            {
                Header: 'Income Sum',
                accessor: 'income_sum',
            },
            {
                Header: 'Url',
                // Cell: ({row}) => row.original['url'] === "" ? "" : row.original['url'].match(/.{1,5}/g).map((value, index) => {
                //     return <span key={index}>{value}</span>
                // }),
                accessor: 'url',
                style: {maxWidth: '200px', overflowWrap : 'break-word'},
            },
            {
                Header: 'Reject reason',
                accessor: 'outcome_reject_reason',
            },
            {
                Header: 'Outcome Sum',
                accessor: 'outcome_sum',
            },
            {
                Header: 'Outcome Url',
                // Cell: ({row}) => row.original['outcome_url'] === "" ? "" : row.original['outcome_url'].match(/.{1,20}/g).map((value, index) => {
                //     return <span key={index}>{value}</span>
                // }),
                accessor: 'outcome_url',
                style: {maxWidth: '200px', overflowWrap : 'break-word'},
            },
            {
                Header: 'Code',
                accessor: 'http_code',
            },

            // {
            //     Header: 'ID',
            //     accessor: keyOriginZoneId,
            // },
            // {
            //     Header: 'UID',
            //     accessor: 'uid',
            // },
            // {
            //     Header: 'Account',
            //     accessor: 'pub_account_name',
            // },
            // {
            //     Header: 'Site Id',
            //     accessor: 'site_id',
            // },
            // {
            //     Header: 'Site Url',
            //     accessor: 'site_url',
            // },
            // {
            //     Header: 'Direction',
            //     Cell: ({row}) => ZoneData.directionName(row.original['direction_id']),
            //     accessor: 'direction_id',
            // },
            // {
            //     Header: 'Source Acc',
            //     accessor: 'c_account_name',
            // },
            // {
            //     Header: 'Manager',
            //     accessor: 'c_manager_name',
            // },
            // {
            //     Header: 'Title',
            //     accessor: 'title',
            // },
            // // {
            // //     Header: 'Postback Url',
            // //     accessor: 'postback_url',
            // // },
            // {
            //     accessor: 'available_link',
            //     className: 'min',
            //     Cell: ({row}) => {
            //         if (Number(row.original['is_available']) === 1) {
            //             return <Link to={"/ex-stat/zone/assign/" + row.original[keyZoneId]} target="_blank" rel="noopener noreferrer"><Icon.UserCheck/></Link>
            //         }
            //         return "";
            //     },
            // },
            // // {
            // //     accessor: 'edit_2',
            // //     className: 'min',
            // //     Cell: ({row}) => <Link to={"/ex-stat/zone/edit-propush/" + row.original[keyZoneId]}><Icon.Edit2/></Link>,
            // // },
            // // {
            // //     accessor: 'edit_3',
            // //     className: 'min',
            // //     Cell: ({row}) => <Link to={"/ex-stat/zone/edit/" + row.original[keyZoneId]}><Icon.Edit3/></Link>,
            // // },
            // {
            //     accessor: 'setup',
            //     className: 'min',
            //     Cell: ({row}) => row.original['postback_setup_id'] !== null
            //         ? <Link to={"/postback-setup/zones/edit/" + row.original['postback_setup_id']}><Icon.Settings/></Link>
            //         : <Link to={"/postback-setup/zones/create/" + row.original[keyZoneId]}><Icon.PlusCircle/></Link>
            // },
        ],
        []
    );

    const initialState = {
        sortBy: [
            {
                id: 'date',
                desc: true
            }
        ]
    }

    return [{
        columns,
        initialState
    }];
}
