import React, {useState, useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import RangeSlider from "react-bootstrap-range-slider";
import moment from "moment";

import Container from "../../components/Container";
import Card from "../../components/Сard";
import ajax from "../../../data/ajax";
import {UrlCreator} from "../../../components/select_data";
import {isDebug, Dump, consoleLog, consoleLogResponse} from "../../../components/development";
import LoadingWrapper from "../../components/LoadingWrapper";
import AlertSuccess from "../../components/AlertSuccess";
import ButtonSubmit from "../../components/ButtonSubmit";
import {useGetRequest, usePutRequest} from "../../../data/RequestData";
import AlertDanger from "../../components/AlertDanger";
import SelectSingle from "../../components/select/SelectSingle";
import {useCheckboxInput, useInput, useSelect} from "../../../components/input-hook";
import ZoneInfo from "../../components/ZoneInfo";
import PreviewTable from "./PreviewTable";
import {round} from "../../../components/Math";
import {
    CustomSettingsTemplate,
    MaxNegativeMargin,
    PhpToJsonInfo,
    PostbackPlaceholdersInfo,
    SubscriptionMPTemplate
} from "./elements";
import DivError from "../../components/form/DivError";
import Alert from "../../components/Alert";
import JsonValidate from "../../components/JsonValidate";

const PostbackSetupZonesFormEdit = ({user}) => {
    let {id} = useParams();

    const [showHistory, setShowHistory] = useState(false);
    const [minMarginPercentage, setMinMarginPercentage] = useState(0);

    const [{data, isLoading, error}] = useGetRequest('ex-stat/zone-conversion/' + id);
    const [{
        data: dataUpdate,
        isLoading: isLoadingUpdate,
        error: errorUpdate,
        dataErrors
    }, setRequestData] = usePutRequest('ex-stat/zone-conversion/' + id);

    const defaultMaxValue = 80

    const inputEnabled = useCheckboxInput(true);
    const inputAccountId = useSelect('');
    const inputPostbackUrl = useInput('');
    const inputForwardPercentage = useInput('50');
    const inputMarginPercentage = useInput('50');
    const inputPrecision = useInput('-1');
    const inputRates = useInput('');
    const inputMinValue = useInput('');
    const inputMaxValue = useInput(defaultMaxValue + '');
    const inputCustomSettings = useInput('');
    const inputSubsPM = useInput('');
    const inputNegativeMargin = useCheckboxInput(false);

    const [accountsList, setAccountsList] = useState([]);
    const [accountType, setAccountType] = useState("?");

    const [version, setVersion] = useState("");
    const [history, setHistory] = useState([]);

    const setFromResponse = (result) => {
        consoleLog('setFromResponse');
        consoleLog(result);

        setVersion(result.version);
        setHistory(Object.entries(result.history).reverse());

        if (result.margin_percentage < 0) {
            inputNegativeMargin.setValue(true);
        }
        inputEnabled.setValue(parseInt(result.enabled) === 1);
        inputForwardPercentage.setValue(result.forward_percentage ? result.forward_percentage : 0);
        inputMarginPercentage.setValue(result.margin_percentage ? result.margin_percentage : 0);
        inputAccountId.setValue(result.account_id ? result.account_id + '' : '');
        inputPostbackUrl.setValue(result.url ? result.url : '');
        inputPrecision.setValue(result.precision ? result.precision : -1);
        inputRates.setValue(result.rate_table ? JSON.stringify(result.rate_table) : '');
        inputMinValue.setValue(result.min_value ? result.min_value + '' : '');
        inputMaxValue.setValue(result.max_value ? result.max_value + '' : '');
        inputCustomSettings.setValue(result.custom_settings ? JSON.stringify(result.custom_settings) : '');
        inputSubsPM.setValue(result.subs_pm ? JSON.stringify(result.subs_pm) : '');
    }

    useEffect(() => {
        // console.log('inputAccountId.value', inputAccountId.value)

        if (inputAccountId.value === null) {
            inputAccountId.setValue('');
        }

        let account = accountsList.filter(x => x.id + '' === inputAccountId.value + '')
        if (account.length > 0) {
            setAccountType(account[0].type)
        }
    }, [inputAccountId.value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const promises = [
                axios.get(UrlCreator.accounts({'type': 'advertiser'}), ajax.getBaseConfig()),
            ];
            Promise.all(promises).then(function (results) {
                consoleLogResponse(results);

                setAccountsList(results[0].data)
                inputAccountId.setOptions([{value: '', label: 'Custom postback'}].concat(results[0].data.map(i => {
                    return {value: i.id + '', label: i.id + ' / ' + i.name + ' / ' + i.postback}
                })));

                // duplicate ((((
                let account = results[0].data.filter(x => x.id + '' === data.account_id + '')
                if (account.length > 0) {
                    setAccountType(account[0].type)
                }
            });

            setFromResponse(data);
        }
    }, [data]);

    useEffect(() => {
        consoleLog('useEffect dataUpdate');
        if (dataUpdate) {
            setFromResponse(dataUpdate);
            window.scrollTo(0, 0);
        }
    }, [dataUpdate]);

    const handleSubmit = (e) => {
        e.preventDefault();

        let minValue = inputMinValue.value.replace(/,/g, '.');
        if (minValue !== '') {
            minValue = Number(minValue);
            if (minValue === 0) {
                minValue = null;
            }
        } else {
            minValue = null;
        }

        let maxValue = inputMaxValue.value.replace(/,/g, '.');
        if (maxValue !== '') {
            maxValue = Number(maxValue);
            if (maxValue === 0) {
                maxValue = defaultMaxValue;
            }
            if (minValue !== null && minValue > maxValue) {
                maxValue = minValue;
            }
        } else {
            maxValue = defaultMaxValue;
        }

        const d = {
            enabled: inputEnabled.value === true ? 1 : 0,
            forward_percentage: parseInt(inputForwardPercentage.value),
            margin_percentage: parseInt(inputMarginPercentage.value),
            account_id: inputAccountId.value + '',
            url: inputAccountId.value === '' ? inputPostbackUrl.value : '',
            precision: inputPrecision.value < 0 ? null : parseInt(inputPrecision.value),
            rate_table: inputRates.value === '' ? null : JSON.parse(inputRates.value),
            min_value: minValue,
            max_value: maxValue,
            custom_settings: inputCustomSettings.value === '' ? null : JSON.parse(inputCustomSettings.value),
            subs_pm: inputSubsPM.value === '' ? null : JSON.parse(inputSubsPM.value),
        };
        setRequestData(d);
    }

    useEffect(() => {
        setMinMarginPercentage(inputNegativeMargin.value ? MaxNegativeMargin : 0);
        if (!inputNegativeMargin.value && (inputMarginPercentage.value + 0 < 0)) {
            inputMarginPercentage.setValue('0');
        }
    }, [inputNegativeMargin.value]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container title={"Edit zone conversion: " + data['origin_zone_id'] + " (version " + version + ")"}>
            <Card>
                <LoadingWrapper isLoaded={!isLoading} error={error}>
                    {errorUpdate !== null && <AlertDanger messagePrefix="Save error:" message={errorUpdate['message']}/>}

                    <form onSubmit={handleSubmit}>
                        {
                            data['zone_postback_url'] && ([7, 8].includes(data['zone_postback_url'].indexOf("trackkingpro.com/" + data['origin_zone_id'] + ".php?")) == false) &&
                            <AlertDanger messagePrefix="Zone postback:" message={data['zone_postback_url']}/>
                        }
                        {
                            data['zone_postback_url'] && [7, 8].includes(data['zone_postback_url'].indexOf("trackkingpro.com/" + data['origin_zone_id'] + ".php?")) &&
                            <AlertSuccess messagePrefix="Zone postback:"
                                          message={data['zone_postback_url'] + '; see source: http://trackkingpro.com/zone_postback.php?zone=' + data['origin_zone_id']}/>
                        }
                        {
                            dataUpdate && <AlertSuccess message="Data updated successfully"/>
                        }

                        <div className="form-group">
                            <label><input type="checkbox" {...inputEnabled.bind} /> Enable outcome postbacks</label>
                        </div>

                        {inputEnabled.value === true && <>
                            <div className="form-group">
                                <label>Advertiser account</label>
                                <SelectSingle {...inputAccountId.bind} />
                                <DivError error={inputAccountId.error}/>
                            </div>

                            {inputAccountId.value === '' && <div className="form-group">
                                <label htmlFor="inputPostbackUrl">Postback Url</label>
                                <input
                                    type="text"
                                    id="inputPostbackUrl"
                                    placeholder="Postback url"
                                    className={"form-control " + (inputPostbackUrl.error.length > 0 ? "is-invalid" : '')}
                                    {...inputPostbackUrl.bind}
                                />
                                <PostbackPlaceholdersInfo url={inputPostbackUrl.value}/>
                                <div>
                                    <Link to="" onClick={e => {
                                        e.preventDefault();
                                        inputPostbackUrl.setValue("https://binomnet3.com/click?cnv_id={click_id}&payout={payout}&cnv_status=&cnv_status2=")
                                    }}>{"Apply binom link: https://binomnet3.com/click?cnv_id={click_id}&payout={payout}&cnv_status=&cnv_status2="}</Link>
                                </div>
                                <DivError error={inputPostbackUrl.error}/>
                            </div>}

                            <div className="form-group">
                                <label>Round precision</label>
                                <RangeSlider
                                    size='lg'
                                    // tooltipLabel={currentValue => `${currentValue}%`}
                                    tooltipLabel={currentValue => {
                                        const v = 0.55555555555;
                                        if (currentValue === -1) {
                                            return v;
                                        }
                                        return 'round(' + v + ', ' + currentValue + ') = ' + round(v, currentValue)
                                    }}
                                    tooltip='on'
                                    min={-1}
                                    max={8}
                                    value={inputPrecision.value}
                                    onChange={changeEvent => inputPrecision.setValue(changeEvent.target.value)}
                                />
                                <DivError error={inputPrecision.error}/>
                            </div>

                            <div className="form-group">
                                <label>Forward Conversion Percentage</label>
                                <RangeSlider
                                    size='lg'
                                    tooltipLabel={currentValue => `${currentValue}%`}
                                    tooltip='on'
                                    min={0}
                                    max={100}
                                    value={inputForwardPercentage.value}
                                    onChange={changeEvent => inputForwardPercentage.setValue(changeEvent.target.value)}
                                />
                                <DivError error={inputForwardPercentage.error}/>
                            </div>

                            <div className="form-group">
                                <label>Margin Percentage</label>
                                <div>
                                    <label className="custom-control custom-checkbox">
                                        <input type="checkbox"
                                               className="custom-control-input" {...inputNegativeMargin.bind} />
                                        <span className="custom-control-label">I want to pay more than I get!</span>
                                    </label>
                                </div>
                                <RangeSlider
                                    size='lg'
                                    tooltipLabel={currentValue => `${currentValue}%`}
                                    tooltip='on'
                                    min={minMarginPercentage}
                                    max={100}
                                    value={inputMarginPercentage.value}
                                    onChange={changeEvent => inputMarginPercentage.setValue(changeEvent.target.value)}
                                    variant={inputMarginPercentage.value < 0 ? 'warning' : 'primary'}
                                />
                                <DivError error={inputMarginPercentage.error}/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputRates">Rates</label>
                                <textarea
                                    id="inputRates"
                                    placeholder="Rates"
                                    className={"form-control " + (inputRates.error.length > 0 ? "is-invalid" : '')}
                                    {...inputRates.bind}>
                                </textarea>
                                <PhpToJsonInfo/>

                                <JsonValidate value={inputRates.value}/>

                                {data['zone_postback_url'] && !((new URL(data['zone_postback_url'])).searchParams.has('geo')) && inputRates.value != "" &&
                                    <Alert
                                        messagePrefix="Postback error:"
                                        message={"To use this field, add the `geo` parameter to the postback URL. Template: &geo={geo}"}
                                        className={"alert-warning"}
                                    />
                                }

                                <DivError error={inputRates.error}/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputMinValue">Min postback value</label>
                                <input
                                    type="text"
                                    id="inputMinValue"
                                    placeholder="min postback value"
                                    className={"form-control " + (inputMinValue.error.length > 0 ? "is-invalid" : '')}
                                    {...inputMinValue.bind}
                                />
                                <DivError error={inputMinValue.error}/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputMaxValue">Max postback value</label>
                                <input
                                    type="text"
                                    id="inputMaxValue"
                                    placeholder="max postback value"
                                    className={"form-control " + (inputMaxValue.error.length > 0 ? "is-invalid" : '')}
                                    {...inputMaxValue.bind}
                                />
                                <DivError error={inputMaxValue.error}/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputCustomSettings">Custom Settings</label>
                                <textarea
                                    id="inputCustomSettings"
                                    placeholder="Custom Settings"
                                    className={"form-control " + (inputCustomSettings.error.length > 0 ? "is-invalid" : '')}
                                    {...inputCustomSettings.bind}>
                                </textarea>
                                <CustomSettingsTemplate/>

                                <JsonValidate value={inputCustomSettings.value}/>

                                {data['zone_postback_url'] && !((new URL(data['zone_postback_url'])).searchParams.has('geo')) && inputCustomSettings.value != "" &&
                                    <Alert
                                        messagePrefix="Postback error:"
                                        message={"To use this field, add the `geo` parameter to the postback URL. Template: &geo={geo}"}
                                        className={"alert-warning"}
                                    />
                                }

                                <DivError error={inputCustomSettings.error}/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputSubsPM">Subscription Payout Multiplier</label>
                                <textarea
                                    id="inputSubsPM"
                                    placeholder="Subscription Payout Multiplier"
                                    className={"form-control " + (inputSubsPM.error.length > 0 ? "is-invalid" : '')}
                                    {...inputSubsPM.bind}>
                                </textarea>
                                <SubscriptionMPTemplate/>

                                <JsonValidate value={inputSubsPM.value}/>

                                {data['zone_postback_url'] && !((new URL(data['zone_postback_url'])).searchParams.has('scnt')) && inputSubsPM.value != "" &&
                                    <Alert
                                        messagePrefix="Postback error:"
                                        message={"To use this field, add the `scnt` parameter to the postback URL. Template: &scnt={sub_cnt}"}
                                        className={"alert-warning"}
                                    />
                                }

                                <DivError error={inputSubsPM.error}/>
                            </div>
                        </>}

                        <div className="form-group">
                            <ButtonSubmit isLoading={false} text="Save changes"/>
                        </div>
                    </form>

                    <PreviewTable
                        forwardPercentage={parseInt(inputForwardPercentage.value)}
                        marginPercentage={parseInt(inputMarginPercentage.value)}
                        precision={parseInt(inputPrecision.value)}
                    />

                </LoadingWrapper>
            </Card>

            <Card>
                <b>Landings: </b>
                <Link
                    to={"/collections/landings?zone=" + data['origin_zone_id'] + "&source=" + accountType}>{"/collections/landings?zone=" + data['origin_zone_id'] + "&source=" + accountType}</Link>
            </Card>

            {Object.keys(data).length > 0 && <ZoneInfo zone_id={data.zone_id}/>}

            <Card>
                <h1><a href="#" onClick={(e) => { setShowHistory(!showHistory); e.preventDefault();}}>{(showHistory ? "Hide history" : "Show History") + " (" + history.length + ")" }</a></h1>
                {
                    showHistory && history.map(([key, vvv]) => {
                        return <>
                            <h3>{vvv.version === version ? "Current" : ""} version {vvv.version} : {moment.unix(key).tz("UTC").format("YYYY-MM-DD HH:mm:ss")} (UTC)</h3>
                            <pre>{JSON.stringify(vvv, null, 2)}</pre>
                        </>
                    })
                }
            </Card>

            {isDebug() && <Card><Dump value={data}/></Card>}
        </Container>
    );
}

export default PostbackSetupZonesFormEdit;
