import React from "react";

export default function Report1ListGroupByParams(user) {
    const keyDate = 'date';
    const keyCampaign = 'campaign_id';
    const keyNetwork = 'network_id';
    const keyAccount = 'account_id';
    const keyManager = 'manager_id';
    const keyDomain = 'domain';
    const keyDomainMain = 'main_domain';
    const keyPath = 'path';
    const keyPathL = 'l_path';
    const keyPathReal = 'real_path';

    const items = React.useMemo(() => [
        {
            title: "Date",
            key: keyDate,
        },
        {
            title: "Campaign",
            key: keyCampaign,
        },
        {
            title: "Network",
            key: keyNetwork,
        },
        {
            title: "Account",
            key: keyAccount,
        },
        {
            title: "Manager",
            key: keyManager,
            visible: user.allowManagers(),
        },
        {
            title: "Full Domain",
            key: keyDomain,
        },
        {
            title: "Domain",
            key: keyDomainMain,
        },
        {
            title: "Path",
            key: keyPath,
        },
        {
            title: "Redirect path",
            key: keyPathL,
        },
        {
            title: "Real path",
            key: keyPathReal,
        },
    ], []);

    return {
        items,
        keyDate,
        keyCampaign,
        keyNetwork,
        keyAccount,
        keyManager,
        keyDomain,
        keyDomainMain,
        keyPath,
        keyPathL,
        keyPathReal,
    };
}
